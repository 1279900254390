import { Vue, Component } from 'vue-property-decorator';
import { State as ClassState, Getter, Action } from 'vuex-class';
import converter from 'number-to-words';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import CheckoutRecap from '@/components/checkout/recap/CheckoutRecap.vue';
import { State } from '@/store/models';
import { BusinessInvestor, PrivateInvestor } from '@/store/models/user';
import { formatNumber, roundNumber } from '@/filters/number';
import { Checkout } from '@/store/models/checkout';
import { Asset } from '@/store/models/asset';
import { PaymentInitData } from '@/store/modules/payment';

interface ContractData {
  name: string,
  surname: string,
  dateOfBirth: number,
  streetAddress: string,
  houseNumber: string,
  postalCode: string,
  city: string,
  country: string,
  email: string,
  totalEurWords: string,
  noteCount: number,
  totalEur: string,
  noteType: string,
  bankAccount: string,
}

@Component({
  components: {
    CheckoutFooter,
    WithDownloads,
    CheckoutRecap,
  },
})

export default class CheckoutLegal extends Vue {
  formatNumber = formatNumber;

  @Getter isEligibleToInvest!: boolean;
  @Getter getCheckout!: Checkout;
  @Getter getAssetById!: (string) => Asset;

  @ClassState user!: State['user'];

  @Action paymentInit!: (data: PaymentInitData) => void;

  get asset(): Asset | undefined {
    return this.getAssetById(this.getCheckout?.assetId);
  }

  get contractData(): ContractData | null {
    if (!this.user) {
      return null;
    }

    // NOTE: Since there is no iDIN in Randstad the user was asserted to be of private or business types. This was necessary as
    // the `name` field is required for the contract data
    const { name, surname, email, dateOfBirth, city, postalCode, streetAddress, houseNumber, country, bankAccount } = this.user as PrivateInvestor | BusinessInvestor;

    const tot = this.getCheckout.eurAmount; // amount without emission costs
    const int = Math.floor(tot);
    const dec = roundNumber(tot - Math.floor(tot), 2) * 100;

    return {
      name,
      surname,
      email,
      dateOfBirth: dateOfBirth.toMillis(),
      noteCount: this.getCheckout.sharesAmount,
      totalEur: formatNumber(tot, 2, true),
      totalEurWords: `${converter.toWords(int)} Euros and ${converter.toWords(dec)} Eurocents`,
      noteType: this.user.type as string,
      city,
      postalCode,
      streetAddress,
      houseNumber,
      country,
      bankAccount,
    };
  }

  initPayment(): void {
    this.paymentInit({
      redirectUrl: `${window.location.origin}/checkout/status/`,
      lang: localStorage.getItem('vue-i18n-language') || 'nl',
      brand: require('../../../../whitelabel.config').brand,
    });
  }
}
