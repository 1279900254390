import { VueConstructor } from 'vue';
import { MetaInfo } from 'vue-meta';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteRecord } from 'vue-router';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import CheckoutHeader from '@/components/checkout/header/CheckoutHeader.vue';
import CheckoutIdentification from '@/components/checkout/identification/CheckoutIdentification.vue';
import { CheckoutInvestment } from '@/components/checkout/index';
import CheckoutLegal from '@/components/checkout/legal/CheckoutLegal.vue';
import CheckoutQuestionnaire from '@/components/checkout/questionnaire/CheckoutQuestionnaire.vue';
import CheckoutStatus from '@/components/checkout/status/CheckoutStatus.vue';
import Loader from '@/components/common/loader/Loader.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { formatNumber } from '@/filters/number';
import { IdentCheckoutRouteNames, IdentifcationPath, steps } from '@/helpers/checkout';
import { titleTemplate } from '@/helpers/meta/title-template';
import { State } from '@/store/models';
import { Checkout as CheckoutState, CheckoutStepNames } from '@/store/models/checkout';
import { requireQuestionnaire } from '../../../whitelabel.config';

@Component({
  components: {
    Loader,
    Navbar,
    CheckoutHeader,
    WithDownloads,
  },
})

export default class Checkout extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.checkout.title').toString()),
    };
  }

  beforeRouteLeave(to, from, next): void {
    // we want to forget the information in the store if we move away from the checkout flow
    if (!(to.name === CheckoutStepNames.Terms || from.name === CheckoutStepNames.Terms || to.name === CheckoutStepNames.Questionnaire || from.name === CheckoutStepNames.Questionnaire)) {
      this.resetCheckoutAction();
    }
    next();
  }

  paymentActivating: boolean = false;
  paymentActivatingEnded: boolean = false;
  private animationTime: number = 2500; // should be in sync with the time of the actual css animation
  currentComponentProps: any = {};
  currentRouteIndex: number = -1;
  formatNumber = formatNumber;

  @Prop({ default: null })
  initWithType!: CheckoutStepNames;

  @StateClass assets!: State['assets'];
  @StateClass user!: State['user'];
  @StateClass payment!: State['payment'];

  @Action(addToastMessage) addToastMessage!: Function;
  @Action resetCheckoutAction!: (initValue?: Partial<Checkout>) => void;
  @Action updateCheckoutAction!: (data: CheckoutState) => void;

  @Getter isEligibleToInvest!: Boolean;
  @Getter getCheckout!: CheckoutState; // since a getter of an undefined state returns an empty object not undefined

  /**
   * Redirecting if fund does not exist
   */
  mounted(): void {
    this.checkAssetExistsRedirect(this.assets);
  }

  @Watch('initWithType', { immediate: true })
  onChangeinitWithType(newType: CheckoutStepNames): void {
    this.checkAndUpdateRouteIfNecessary(newType);
    /**
     * calculate current route index
     * if at the status page = last step
     * otherwise check by the steps array
     * */
    let routeIndex = -1;
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name === 'checkoutStatus') {
      this.currentRouteIndex = steps.length;
      return;
    }
    steps.some(({ route }, index): boolean => {
      if (currentRoute.matched.some((matched: RouteRecord): boolean => matched.path.substring(0, route.length) === route)) {
        routeIndex = index;
        return true;
      }
      return false;
    });
    this.currentRouteIndex = routeIndex;
    this.updateCheckoutAction({
      ...this.getCheckout,
      nextStep: steps[routeIndex + 1].name ?? 'lastStep',
    });
  }

  @Watch('user', { immediate: false })
  onChangeUser(newUser: CheckoutStepNames): void {
    this.checkAndUpdateRouteIfNecessary(this.initWithType);
  }

  @Watch('payment.status')
  onPaymentChanged(newStatus: string, oldStatus: string): void {
    const { payment } = this.$store.state;
    this.paymentActivating = true; // initialise the animation
    const isSuccess = newStatus === 'success' && oldStatus === 'processing';
    /**
     * optimise load of next page
     * */
    if (isSuccess) {
      const prerender = document.createElement('link');
      prerender.href = payment.payload && payment.payload.data.payment.checkoutUrl;
      prerender.rel = 'prerender'; // prerender is not supported in all browsers
      prerender.as = 'document';
      document.head.appendChild(prerender);
      const predns = document.createElement('link');
      predns.href = payment.payload && payment.payload.data.payment.checkoutUrl;
      predns.rel = 'dns-prefetch'; // thus also the dns-prefetch
      document.head.appendChild(predns);
    }
    // only redirect after the animation, at the same time during the animation the actual state changes / firestore updates are executed
    setTimeout((): void => {
      if (isSuccess) {
        window.location.assign(payment.payload && payment.payload.data.payment.checkoutUrl);
      }

      if (newStatus === 'error' && oldStatus === 'processing') {
        this.addToastMessage({
          text: payment.error,
          type: 'danger',
        });
      }
    }, this.animationTime);
  }

  // this pattern allows for exhaustiveness checks in ts
  // eslint-disable-next-line consistent-return
  get currentComponent(): VueConstructor<Vue> | null {
    // eslint-disable-next-line default-case
    switch (this.initWithType) {
      case CheckoutStepNames.Identification:
        this.currentComponentProps = { header: false };
        return CheckoutIdentification;
      case CheckoutStepNames.Investment:
        return CheckoutInvestment;
      case CheckoutStepNames.Questionnaire:
        return CheckoutQuestionnaire;
      case CheckoutStepNames.Terms:
        return CheckoutLegal;
      case CheckoutStepNames.Status:
        return CheckoutStatus;
      case CheckoutStepNames.Payment:
        return null;
    }
  }

  get investmentId(): string {
    return this.$route.params.id || this.$route.params.investmentId;
  }

  /**
   * Get current asset.
   */
  get asset(): object | undefined {
    return this.assets.find((asset): any => asset.id === this.investmentId);
  }

  /**
   * Checking if the asset exists, redirecting if not.
   */
  checkAssetExistsRedirect(assets): void {
    if (assets.length > 0 && !this.asset) {
      this.$router.replace('/error/404').catch((): void => {});
    }
  }

  /**
   * move to identification, or investment step if required
   */
  private checkAndUpdateRouteIfNecessary(newType: CheckoutStepNames): void {
    // this logic should be changed after we change the general logic of after which actions one can make a payment
    // move to identification if that's still required
    if (this.user && !this.isEligibleToInvest) {
      if (
        (newType === CheckoutStepNames.Investment || newType === CheckoutStepNames.Terms)
        || ((this.user.questionnaire && newType === CheckoutStepNames.Questionnaire) || !requireQuestionnaire)
      ) {
        this.$router.replace({ path: `/${this.$route.params.lang}/checkout/${IdentifcationPath}/${this.investmentId}/` });
        return;
      }
      if (this.user.questionnaire === undefined && newType === CheckoutStepNames.Identification && requireQuestionnaire) {
        this.$router.replace({ path: `/${this.$route.params.lang}/checkout/questionnaire/${this.investmentId}/` });
        return;
      }
    }

    // move to investment if no selection has been made yet or if already identified
    // if it was done via idin we show the idin success
    if (((this.getCheckout.assetId !== this.investmentId && newType === CheckoutStepNames.Terms)
      || (newType === CheckoutStepNames.Identification && this.user && this.isEligibleToInvest)) && !(this.$route.name === IdentCheckoutRouteNames.IDIN)) {
      this.$router.replace({ path: `/${this.$route.params.lang}/checkout/investment/${this.investmentId}/` });
    }
  }

  afterEnter(): void {
    this.paymentActivatingEnded = true;
  }
}
