import { Vue, Component, Prop } from 'vue-property-decorator';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';

@Component({
  components: {
    WithDownloads,
  },
})

export default class CheckoutRecap extends Vue {
  @Prop({ required: true }) asset!: any;
  @Prop({ required: true }) getCheckout!: any;
}
