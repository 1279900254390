import { ValidationObserver } from 'vee-validate';
import { Vue, Component, Ref } from 'vue-property-decorator';
import { State as ClassState, Getter, Action } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import CheckoutRecap from '@/components/checkout/recap/CheckoutRecap.vue';
import FormFileInput from '@/components/common/form-elements/FormFileInput.vue';
import FormTextArea from '@/components/common/form-elements/FormTextArea.vue';
import QuestionnaireComponent from '@/components/common/questionnaire/Questionnaire.vue';
import Questionnaire from '@/components/common/questionnaire/Questionnaire';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import { State } from '@/store/models';
import { Checkout, CheckoutStepNames } from '@/store/models/checkout';
import { Asset } from '@/store/models/asset';
import { PaymentInitData } from '@/store/modules/payment';

type Toaster = { text: string, type: 'info' | 'success' | 'warning' | 'danger', dismissAfter: number };

@Component({
  components: {
    CheckoutFooter,
    WithDownloads,
    FormTextArea,
    FormFileInput,
    ValidationObserver,
    QuestionnaireComponent,
    CheckoutRecap,
  },
})

export default class CheckoutQuestionnaire extends Vue {
  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;
  @Ref('questionnaireRef') readonly questionnaireRef!: Questionnaire;

  @Getter getCheckout!: Checkout;
  @Getter getAssetById!: (string) => Asset;

  @ClassState user!: State['user'];

  @Action paymentInit!: (data: PaymentInitData) => void;
  @Action(addToastMessage) addToastMessage!: (arg0: Toaster) => any;
  CheckoutStepNames = CheckoutStepNames;

  get asset(): Asset | undefined {
    return this.getAssetById(this.getCheckout?.assetId);
  }

  async nextStep(): Promise<void> {
    const valid = await this.form.validate();
    if (!valid) {
      this.addToastMessage({ dismissAfter: 1000, text: 'Form contains invalid fields', type: 'danger' });
      return;
    }
    await this.questionnaireRef.submit();
    if (this.getCheckout.nextStep === CheckoutStepNames.Terms) {
      await this.$router.push({ path: `/${this.$route.params.lang}/checkout/investment/${this.getCheckout.assetId}/` });
    } else if (this.getCheckout.nextStep === CheckoutStepNames.Payment) {
      this.paymentInit({
        redirectUrl: `${window.location.origin}/checkout/status/`,
        lang: localStorage.getItem('vue-i18n-language') || 'nl',
        brand: require('../../../../whitelabel.config').brand,
      });
    }
  }
}
