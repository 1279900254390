import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { DownloadPath } from '@/store/modules/downloads/downloads';

@Component
export default class WithDownloads extends Vue {
  @Prop() path!: DownloadPath;

  @Action getDownloads!: Function;

  @Getter getDownloadsByPath!: Function;
  @Getter isDownloadsProcessing!: Function;
  @Getter didDownloadsFail!: Function;

  @Watch('path', { immediate: true, deep: true })
  onPathChange(newPath: DownloadPath): void {
    if (this.isIdAvailable(newPath)) {
      const propArray = this.createPropArray(newPath.properties);
      propArray.forEach((property: string): void => {
        const dlPath = { ...newPath, properties: property };
        // Only get items which are not already downloaded
        if (!this.getDownloadsByPath(dlPath) && !this.isDownloadsProcessing(dlPath) && !this.didDownloadsFail(dlPath)) {
          this.getDownloads(dlPath);
        }
      });
    }
  }

  // Collect the downloads to an object that will be passed down to the children components
  get downloads(): { [key: string]: string } | undefined {
    if (!this.isIdAvailable(this.path)) {
      return undefined;
    }

    const propArray = this.createPropArray(this.path.properties);
    const buildDownloadObject = (obj: { [key: string]: string }, key: string): { [key: string]: string } => {
      obj[key] = this.getDownloadsByPath({ ...this.path, properties: key });
      return obj;
    };

    return propArray.reduce(buildDownloadObject, {});
  }

  get firstImage(): string | undefined {
    const dl = this.downloads;
    return (dl && dl.images && dl.images.length) ? dl.images[0][1] : undefined;
  }

  isIdAvailable(path: DownloadPath): boolean {
    return path && path.hasOwnProperty('id') && !!path.id;
  }

  createPropArray(props: string | string[]): string[] {
    return Array.isArray(props) ? props : [props];
  }
}
