import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { collections } from '@/vue';
import Loader from '@/components/common/loader/Loader.vue';
import { Payment, PaymentStatus } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';

@Component({
  components: {
    Loader,
  },
})

export default class CheckoutStatus extends Vue {
  collections = collections;

  @Getter getAssetByInvestmentId!: Function;
  @Getter getPaymentById!: Function;

  /**
   * Get payment
   *
   * @returns {Investment}
   */
  get payment(): Payment | undefined {
    return this.getPaymentById(this.$route.params.paymentId);
  }

  get asset(): Asset {
    return this.payment?.asset as Asset;
  }

  /**
   * Return quantity of Bloqs bought.
   *
   * @returns {number}
   */
  get sharesBought(): number {
    return Number(this.payment?.providerData.metadata!.sharesAmount);
  }

  /**
   * Return amount of EUR bought.
   *
   * @returns {number}
   */
  get euroBought(): number {
    return this.payment?.providerData.metadata.euroAmount || 0;
  }

  /**
   * Return payment status
   *
   * @returns {number}
   */
  get paymentStatus(): PaymentStatus | undefined {
    return this.payment?.providerData.status;
  }

  // Opening the support box from AccountDashboard
  handleSupport(): void {
    this.$emit('handle-support-event');
  }
}
