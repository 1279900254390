import moment from 'moment';
import { Asset } from '@/store/models/asset';

export function dividendsReturnsOptions(asset: Asset | undefined): [string, number][] {
  if (!asset) {
    return [];
  }

  const durationFormatted = (): string => {
    if (!asset || !asset.endDateTime) {
      return '';
    }

    const now = moment();
    const yearsTemp = moment.unix(asset.endDateTime.seconds).diff(now, 'years', true);

    // If no years remaining, put 1
    return (Math.ceil(yearsTemp)).toString();
  };

  return asset.fixedDividends
    ? [[durationFormatted(), asset.returnsAfterEnd!]]
    : asset.dividendsFormat.map((dF): [string, number] => dF.contents);
}
